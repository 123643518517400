import React from 'react';
import './q&a.scss';
import QandaComponent from '../../components/qanda/qanda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QandASection: React.FC = () => {
  return (
    <div className="qanda_container">
      <h2>Frequently Asked Questions</h2>
      <div className="qanda">
        <QandaComponent question="What is urbanjungle.care?">
          <p>Urbanjungle.care is a cutting-edge mobile app designed to assist you in caring for your tropical
            houseplants.<br/> It utilizes BLE (Bluetooth Low Energy) sensors to gather crucial data and offers tailored
            advice to keep your plants thriving.</p>
        </QandaComponent>
        <QandaComponent question="How does it work?">
          <p>The app connects to BLE sensors placed near your plants, collecting information about their
            environment.<br/> This data is then analyzed to provide personalized care recommendations.</p>
        </QandaComponent>
        <QandaComponent question="Why can't I share an accounts on iOS?">
          <p>iOS does not expose the MAC address of the device, and each device has its own unique identifier, making it
            impossible to share accounts across different devices.</p>
        </QandaComponent>
        <QandaComponent question="Why should I use urbanjungle.care?">
          <p>Urbanjungle.care takes the guesswork out of plant care. Whether you're a seasoned plant enthusiast or just
            starting out, our app ensures your plants get the care they deserve.</p>
        </QandaComponent>
        <QandaComponent question="Is my information secure on urbanjungle.care?">
          <p>
            Absolutely! Your data's security is our top priority. We use industry-standard security measures to protect
            data and we only use the essential data to give you precise care guidance for your plants.
          </p>
        </QandaComponent>
        <QandaComponent question="How to delete my account and personal data?">
          <p>
            1. Go to the profile page in the app.<br/>
            2. Click on the "Delete your account" button.<br/>
            3. Confirm the deletion.<br/><br/>
            <span>Note: All personal data associated with your account will be permanently deleted.</span>
          </p>
        </QandaComponent>
        <QandaComponent question="What sensors are supported?">
          <p>On launch the Xiaomi Mi Flower Xiaomi and Vegtrug Flora Plant Sensor are supported.</p>
        </QandaComponent>
        <QandaComponent question="Can I connect multiple sensors?">
          <p>Yes, urbanjungle.care allows you to connect and monitor multiple sensors, making it perfect for caring for
            a diverse collection of plants.</p>
        </QandaComponent>
        <QandaComponent question="Do I need to be a plant expert?">
          <p>Not at all! urbanjungle.care is designed for everyone, regardless of their level of plant knowledge. Our
            user-friendly interface and personalized recommendations make plant care a breeze.</p>
        </QandaComponent>
      </div>

      <div className="contact">
        <p>Didn't find the answer you were looking for? Contact us: </p>
        <a className="icon" href="mailto:info@urbanjungle.care" target={'_blank'}
           rel={'noreferrer'}>
          <span className="icon">
            <FontAwesomeIcon icon={'envelope'}/>
          </span>
          <span>Ask your question</span>
        </a>
      </div>

    </div>
  );
};

export default QandASection;
