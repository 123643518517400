import React, { useEffect, useState } from 'react';
import './hero.scss';
import { StaticImage } from 'gatsby-plugin-image';
// @ts-ignore
import KoktailLogo from '../../assets/images/koktail-logo-transparant.svg';

type HeroProps = {
  children?: React.ReactChild
  size: 'small' | 'normal'
}
const Hero = ({ size }: HeroProps) => {
  const storageKey = 'subscribed';
  const [subscribed, setSubscribed] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<undefined | null | string>(undefined);

  useEffect(() => {
    if (localStorage.getItem(storageKey)) {
      setSubscribed(localStorage.getItem(storageKey));
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError(null);
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateEmail(email)) {
      const url = 'https://api.urbanjungle.care/api/users';

      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email
          }),
        });

        if (res.status === 201) {
          localStorage.setItem(storageKey, email);
          setSubscribed(email);
        } else {
          setEmailError('Could not subscribe, please try again later.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setEmailError('Not a valid email address');
    }
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className={ `hero-container hero-container--${ size }` }>
      <div className={ `hero hero--${ size }` }>
        <div className="hero-image">
          <StaticImage
            placeholder="blurred"
            quality={ 90 }
            src="../../assets/images/hero.png"
            alt="urban jungle hero"
          />
        </div>
        <div className="hero-text">
          <h2>Subscribe for news.</h2>
          <p>
            Stay in the loop with the latest urban jungle care news! We'll keep you updated on the newest features to keep your houseplants thriving.
          </p>
          {
            subscribed ?
              <div className="subscribe">
                <p>
                  Thanks you are subscribed with: <b>{ subscribed }</b><br/>
                </p>
              </div>
              :
              <div className="subscribe">
                <div>
                  <div>
                    <form onSubmit={ handleSubmit }>
                      <input onChange={ handleEmailChange } className={ emailError ? 'input input-error' : 'input' }
                             placeholder="Your email address"/>
                      <button type="submit" className="button button--primary">
                        <span>Subscribe</span>
                      </button>
                    </form>
                    { emailError && <label className="error-message">{ emailError }</label> }
                  </div>
                </div>
              </div>
          }

          <div className="by">
            <span>Developed by:</span>
            <a href="https://www.koktail.nl" target="_blank">
              <div className="logo">
                <KoktailLogo/>
              </div>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};
export default Hero;
