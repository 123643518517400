import React from 'react';
import './discover.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function QandA() {
  return (
      <div className="discover_container">
        <div className="discover">
          <div className="discover-head">
            <div className="discover-text">
              <h1>Now Available</h1>
              <p>
                Exciting news! Our urban jungle care app is out! Connect your houseplants to our app via BLE
                sensors for health analysis and personalized care advice.
              </p>

              <div className="contact">
                <p>We'd love to hear your thoughts and suggestions!
                  <a className="icon" href="mailto:info@urbanjungle.care" target={'_blank'}
                     rel={'noreferrer'}>
                  <span className="icon">
                    <FontAwesomeIcon icon={'envelope'}/>
                  </span>
                    <span>Let us know!</span>
                  </a>
                </p>
              </div>


            </div>
            <div className="discover-button">
              <a href="https://www.urbanjungle.care/redirect-to-beta-release-android" target="_blank">
                <StaticImage
                  placeholder="blurred"
                  quality={100}
                  src="../../assets/images/download-android-app.png"
                  alt="Download the android app"
                />
              </a>
              <a href="https://www.urbanjungle.care/redirect-to-beta-release-ios" target="_blank">
                <StaticImage
                  placeholder="blurred"
                  quality={100}
                  src="../../assets/images/download-ios-app.png"
                  alt="Download the ios app"
                />
              </a>
            </div>
          </div>
          <div className="discover-image">
            <StaticImage
              placeholder="blurred"
              quality={100}
              src="../../assets/images/example.png"
              alt="Discover the app"
            />
          </div>
        </div>
      </div>
  );
}
